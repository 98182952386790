import { Paginated } from '@feathersjs/feathers'

export type FeathersResult<T> = T | T[] | Paginated<T>

/**
 * Extract the first result from feathers
 * @param result
 */
export function getFirstResult<T> (result: FeathersResult<T> | undefined): T | null {
  if (Array.isArray(result)) {
    return result[0] || null
  } else if (result && 'total' in result && 'limit' in result && 'skip' in result && 'data' in result) {
    return result.data[0] || null
  }
  return result || null
}

/**
 * Normalise a feathers returned result
 * @param result
 */
export function feathersResultToArray<T> (result: FeathersResult<T> | undefined): T[] {
  if (Array.isArray(result)) {
    return result
  } else if (result && 'total' in result && 'limit' in result && 'skip' in result && 'data' in result) {
    return result.data
  }
  return result ? [result] : []
}

/**
 * Round the given number up based upon the decimal places
 * @param number number to round up
 * @param places number of decimal places to round up to (defaults to 2)
 */
export const roundNumberUp = (number: number, places = 2): number => {
  return Math.ceil(number * (10 ** places)) / (10 ** places)
}

/**
 * Round the given number down based upon the decimal places
 * @param number number to round down
 * @param places number of decimal places to round up to (defaults to 2)
 */
export const roundNumberDown = (number: number, places = 2) => {
  return Math.floor(number * (10 ** places)) / (10 ** places)
}

/**
 * Round the given number based upon the decimal places
 * @param number number to round
 * @param places number of decimal places to round up to (defaults to 2)
 */
export const roundNumber = (number: number, places = 2) => {
  return Math.round(number * (10 ** places)) / (10 ** places)
}
